const sounds = {
    "nuwnu": "./sounds/Paroot notification sound.mp3",
    "Gumi": "./sounds/Gumi.mp3",
    "OHaYo": "./sounds/O Ha Yo 3.mp3",
    "angry": "./sounds/angry.mp3",
    "Knockin'": "./sounds/Knockin'.mp3",
    "wuewuewuewuewue": "./sounds/wuewuewuewuewue.mp3",
    "Red birb Alarm Clock": "./sounds/Red birb Alarm Clock.mp3",
    "shortSound1": "./sounds/shortSound1.mp3",
    "shortSound2": "./sounds/shortSound2.mp3",
    "shortSound3": "./sounds/shortSound3.mp3",
    "shortSound4": "./sounds/shortSound4.mp3",
    "shortSound5": "./sounds/shortSound5.mp3",
    "shortSound6": "./sounds/shortSound6.mp3",
    "shortSound7": "./sounds/shortSound7.mp3",
    "shortSound8": "./sounds/shortSound8.mp3",
    "shortSound9": "./sounds/piyo II.mp3",
    "shortSound10": "./sounds/shortSound10.mp3",
    "shortSound11": "./sounds/shortSound11.mp3",
    "bye bye": "./sounds/bye bye.mp3",
    "shaking tail": "./sounds/shaking tail.mp3",
    "Phrase1": "./sounds/Phrase1.mp3",
    "Phrase2": "./sounds/Phrase2.mp3",
    "Strange voice": "./sounds/Strange voice.mp3",
    "GUMIZILLA1": "./sounds/GUMIZILLA1.mp3",
    "GUMIZILLA2": "./sounds/GUMIZILLA2.mp3",
    "GUMIZILLA3": "./sounds/GUMIZILLA3.mp3",
}

export default sounds
